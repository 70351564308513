// Autogenerated
// -------------
// gulpfile.js --> generateBuildInfo

export const Build = {
    version: "6.22.60 (c3627d34f97fcc6e98ceef7fbea6e090)",
    jsVersion: "7e48530baf07daa1d82796f9c209647378a1030a",
    wasmJsSize:  189751,
    wasmVersion: "df90df0fd894cf6ee026bf847969c3ce",
    wasmSize: 1808397,
    jsSize:  6657376,
    buildSeed:  1622012539860,
};
